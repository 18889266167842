import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage2Avaleht from "./pages/Webpage2Avaleht";
import Webpage3Tooted from "./pages/Webpage3Tooted";
import Webpage4Ajurveeda from "./pages/Webpage4Ajurveeda";
import Webpage5KONTAKT from "./pages/Webpage5KONTAKT";
import Webpage6Konsultatsioon from "./pages/Webpage6Konsultatsioon";
import Webpage7Teraapiad from "./pages/Webpage7Teraapiad";
import Webpage8Toode from "./pages/Webpage8Toode";
import Webpage9Ajurveeda_soovitab from "./pages/Webpage9Ajurveeda_soovitab";
import Webpage10M____gitingimused from "./pages/Webpage10M____gitingimused";
import Webpage11Privaatsuspoliitika from "./pages/Webpage11Privaatsuspoliitika";
import Webpage12Soovitused_kevadeks from "./pages/Webpage12Soovitused_kevadeks";
import Webpage13Soovitused_s__giseks from "./pages/Webpage13Soovitused_s__giseks";
import Webpage14Soovitused_talveks from "./pages/Webpage14Soovitused_talveks";
import Webpage15Maksa from "./pages/Webpage15Maksa";
import Webpage16K__psised from "./pages/Webpage16K__psised";
import Webpage17Ajurveeda_kehat____bid from "./pages/Webpage17Ajurveeda_kehat____bid";
import Webpage18Not_found from "./pages/Webpage18Not_found";
import Webpage19Terapeudid from "./pages/Webpage19Terapeudid";
import Webpage20Soovitused_suveks from "./pages/Webpage20Soovitused_suveks";

var baseURL = 'https://my.prana.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiYjYzOGMxYWQyZTU2ZWI1YzExM2YyYWQ0YTczZDRjMDk0ZTUyMmNlNjRjYWUwOGM2YmIwNDFmNjg0ZTNmMzBiN2QzNzNhYjMxNDFjYTQyMDMiLCJpYXQiOjE3MzI2MDYyMTQuNDY3MDM3LCJuYmYiOjE3MzI2MDYyMTQuNDY3MDUsImV4cCI6MTczMjY5MjYxNC40NjI3NzcsInN1YiI6IjY0Iiwic2NvcGVzIjpbXX0.pvHH25Q7KuXFCN906FCwkC7BbfRlD0vw8FWKSLTcq9R_ZFJfYFmZZxv3ilfGBuZUEJIOyDvcmDHkZ2--MR_d5Gh9kD8elKWKzuTma_zlY1OUiNHTwAfNLgUosBLsYaViI-zPXLHn3f8Z2fqa32imTni6lMfcxuqGIEqVS_zUZ4jc9YszufjQAyi9vTpiB_95ZgEivwKsYqDmeRLvq0TP2gBqMGCXJZKPUeHFT2h1uHNe-54lV447lbF4vOcfCt_G6UgpO1Nxc55hvlQT-fB6zz7J2LbA3cFmLLOtQ-VIee358tZ4WFAJGIVy1cFXTge4UEfhFMH_BzWZm4B68kWcZjhJSWEvh7UuVs9jZn4GleFnluMInFdAo3Fp8RLWhspmFQHLx7x3Nkx8x8vLw7kGbeEbGXLgp3KBNG52_m9D7tGiOK_mooOZRWhjmh4RT8b3k-vF9Oav-LJs12_PZfVCAkB9Rhy5mItUuE4Z1yFrNHrrij-crzFchG9jNH0RvCuuYEfzgoBl7Lp89O3Ft9_d4sqlUHcnPqpArctMN-za-9RejZz-FN2KfC0ncXh-8l7obYrTEr_up5BSK2U4_YqoD3ZeVkgeNTRF-vPlDXH4i1VXjxngCVI2dmmaCdtTEWGHpD-OVhfRMiVzEVoZuM7Ef4ther6Bm8MdJ-_nK2Qc_zM',
    'Access-api-key': "eyJpdiI6IjN2Z0pCNXl0UzExNnFvRmdBS2EwcGc9PSIsInZhbHVlIjoiSkxuZlVDVG9WVi9icCtvTmQwYk5DQT09IiwibWFjIjoiYmNiN2U2N2RhN2NmYzM0ODE3ZjA3Zjc0MzFhMDAxM2EwYjYxMDcyNmJiMDFiZGU1Njk4Y2MyYzUxYzNjZDkzYSIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6Ik1jUTZ4cmNUZDVibk9QVUlGdzB2VHc9PSIsInZhbHVlIjoidCtNMzZseEprdG90SXQ5eFRBQnhEUT09IiwibWFjIjoiOWNiYWVhZTViMmQ0ZDZmOWU1NmNlZDE2ZDUzMWIxNjA0MjhmZjFmMDU3YTBkMTNlNjYzYTdmMmMxMDVkNWU2MiIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "GTM-NDMXWK5"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage2Avaleht />} exact />;
                                                                    <Route path="/et/tooted" element={<Webpage3Tooted />} exact />;
                                                                    <Route path="/et/ayurveda" element={<Webpage4Ajurveeda />} exact />;
                                                                    <Route path="/et/contact" element={<Webpage5KONTAKT />} exact />;
                                                                    <Route path="/et/consultation" element={<Webpage6Konsultatsioon />} exact />;
                                                                    <Route path="/et/therapy" element={<Webpage7Teraapiad />} exact />;
                                                                    <Route path="/et/toode/:id" element={<Webpage8Toode />} exact />;
                                                                    <Route path="/et/ayurveda/recommend" element={<Webpage9Ajurveeda_soovitab />} exact />;
                                                                    <Route path="/et/terms" element={<Webpage10M____gitingimused />} exact />;
                                                                    <Route path="/et/privacy" element={<Webpage11Privaatsuspoliitika />} exact />;
                                                                    <Route path="/et/ayurveda/seasonalSpring" element={<Webpage12Soovitused_kevadeks />} exact />;
                                                                    <Route path="/et/ayurveda/seasonalAutumn" element={<Webpage13Soovitused_s__giseks />} exact />;
                                                                    <Route path="/et/ayurveda/seasonalWinter" element={<Webpage14Soovitused_talveks />} exact />;
                                                                    <Route path="/et/checkout" element={<Webpage15Maksa />} exact />;
                                                                    <Route path="/et/cookiePolicy" element={<Webpage16K__psised />} exact />;
                                                                    <Route path="/et/ayurveda/bodytypes" element={<Webpage17Ajurveeda_kehat____bid />} exact />;
                                                                    <Route path="/et/*" element={<Webpage18Not_found />} exact />;
                                                <Route path="*" element={<Webpage18Not_found />} exact />;
                                                                    <Route path="/et/terapeudid" element={<Webpage19Terapeudid />} exact />;
                                                                    <Route path="/et/ayurveda/seasonalSummer" element={<Webpage20Soovitused_suveks />} exact />;
                                                                
                                            <Route path="/product/:id" element={<Redirect to="/toode/:id" />} exact />;
                                            <Route path="/products" element={<Redirect to="/tooted" />} exact />;
                                                            </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}